import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SectionForm from './pagebuilder/section-form';
import { AiOutlineClose } from 'react-icons/ai';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '500px',
    margin: '0 auto',
    overflowY: 'auto',
  },
  paper: {
    width: '80vw',
    backgroundColor: 'white',
    borderRadius: '5px',
    margin: theme.spacing(2, 2, 2),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 2),
  },
  phoneLink: {
    paddingTop: '25px',
    textAlign: 'center',
    '& > a': {
      textDecoration: 'underline',
    },
  },
  root: {
    width: '100%',
    backgroundColor: 'white',
  },
}));

export default function BuyNowModal({ modalState, isOpen }) {
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) modalState(true);
    else modalState(false);
  }, [isOpen]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => modalState(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div id="transition-modal-title" style={{ paddingBottom: '1rem' }}>
            <h3 style={{ textAlign: 'center' }}>
              Contact Us
              <AiOutlineClose style={{ float: 'right', fontSize: '14px' }} onClick={() => modalState(false)} />
            </h3>
          </div>
          <Divider />
          <div className={classes.phoneLink}>
            {`Call Us: `}
            <a href="tel:(480) 431-2230" target="_blank" rel="noopener">
              (480) 431-2230
            </a>
          </div>
          <SectionForm section={{ _key: 'buyNowModalKey', centered: true }} />
        </div>
      </Fade>
    </Modal>
  );
}

BuyNowModal.propTypes = {
  modalState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
