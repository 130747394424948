import React from 'react';
import Heading from '../reusecore/Heading';
import { Link } from 'gatsby';
import { flatten, uniqBy } from 'lodash';
import Box from '../reusecore/Box';
import { List, ListItem } from '../reusecore/List';
import Text from '../reusecore/Text';

export default function SidebarProduct({ itemTypes, manufacturers, items }) {
  /* temporarily disable tags */
  /* let tags = items?.nodes.map((item) => {
    return item.tags;
  });
  tags = uniqBy(flatten(tags), 'label');
  */
  return (
    <div style={{ margin: '2rem 0' }}>
      {itemTypes?.map((itemType) => (
        <ul key={`nav-${itemType.id}`} style={{ paddingLeft: '0' }}>
          <li style={{ listStyle: 'none' }}>
            <Heading leftAlign as="h3">
              <Link to={`/${itemType.slug.current}`}>{itemType.title}</Link>
            </Heading>
          </li>
          <li style={{ listStyle: 'none' }} key={`nav-${itemType.id}`}>
            <ul style={{ paddingLeft: '0' }}>
              {manufacturers?.nodes?.map((manufacturer) => {
                const filteredItems = items?.nodes?.filter((item) => {
                  return item.itemType?.id === itemType?.id && manufacturer?.id === item.manufacturer?.id;
                });
                const filteredManufacturerItems = uniqBy(filteredItems, 'manufacturer.id');
                return filteredManufacturerItems?.map((item) => {
                  if (item.itemType?.id === itemType.id && manufacturer?.id === item.manufacturer?.id) {
                    return (
                      <li style={{ listStyle: 'none' }} key={`nav-${manufacturer?.id}`}>
                        <Link to={`/${itemType.slug.current}/${manufacturer.slug.current}`}>{manufacturer.name}</Link>
                      </li>
                    );
                  }
                });
              })}
            </ul>
          </li>
        </ul>
      ))}
      {/*<>
        <Box>
          <Heading leftAlign as="h3">
            Tags
          </Heading>
        </Box>
        <List>
          {tags?.map((tag, index) => {
            // hack to remove sanity tag Create being saved in label
            // https://github.com/rosnovsky/sanity-plugin-autocomplete-tags/issues/14
            let displayTag = tag.label;
            const createIndex = displayTag.indexOf('Create "');
            if (createIndex !== -1) {
              displayTag = displayTag.substring(8, displayTag.length - 1);
            }
            return (
              <ListItem key={index}>
                <Link to={`/shop?tag=${tag.value.toLowerCase()}`}>
                  <Text as="span">{displayTag.toLowerCase()}</Text>{' '}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </>*/}
    </div>
  );
}
