import styled from 'styled-components';

const ProductPageWrapper = styled.section`
  //margin-top: 130px;
  .slick-list {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .slick-slide img {
    margin: 0 auto;
  }
  .product-details {
    padding: 0 0 20px 0;
  }
  .product-image-slider {
    position: relative;

    ul {
      position: unset;
      margin-top: 1.5rem;
    }

    .product__thumb {
      display: flex !important;
      flex-wrap: wrap;
      text-align: left;
      z-index: 22;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 70px;
        height: 70px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        opacity: 0.5;
        a {
          display: inline-block;
        }

        &.slick-active {
          opacity: 1;
          box-shadow: 0 0 10px #e4e4e4;
        }
      }
    }
  }

  .specifications {
    h2 {
      margin-bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        padding: 0;
        display: flex;

        span {
          &:first-child {
            width: 45%;
          }

          &:nth-child(2) {
            font-weight: 600;
          }
        }
        .color-list {
          display: inline-flex;
          .color {
            background: #ddd;
            border-radius: 50%;
            margin-right: 10px;
            padding: 14px;
          }
          .Black {
            background: #42393c;
          }
          .Blue {
            background: #0083d0;
          }
          .red {
            background: #dc4d41;
          }
        }
      }
    }
  }

  .about-pera {
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: 500px;
    li {
      margin-bottom: 10px;
    }
  }

  .product-rating-wrap {
    padding: 80px 0;
  }
  .rating-icon {
    font-size: 20px;
    color: ${(props) => props.theme.secondaryColor};
    height: 60px;
    width: 60px;
    background: #ffe6e7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .product-rating-block {
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px #ececec;
    margin-bottom: 20px;
    h3 {
      margin-bottom: 10px;
    }
  }
  .rating-range {
    svg {
      color: ${(props) => props.theme.secondaryColor};
    }
  }

  @media only screen and (max-width: 1024px) {
    .product-details {
      padding: 0 0 60px 0;
    }
  }
  @media only screen and (max-width: 912px) {
    .product-details {
      padding: 0;
    }
    .product-image-slider {
      .product__thumb {
        bottom: -200px;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .product-image-slider {
      .product__thumb {
        bottom: -115px;
        text-align: center;
      }
    }
    .specifications {
      margin-top: 200px;
    }
  }
  @media only screen and (max-width: 480px) {
    .product-image-slider {
      .product__thumb {
        li {
          height: 60px;
          width: 60px;
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .product-image-slider {
      .product__thumb {
        //display: none !important;
      }
    }
  }
`;

export default ProductPageWrapper;
