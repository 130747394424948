import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Container from '../components/layout/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import SinglePage from '../components/sections/essentialPages/ProductPage';
import SectionHero from '../components/pagebuilder/section-hero';
import Navigation from '../components/sections/cycle/Navigation';
import BuyNowModal from '../components/buy-now-modal';

export const query = graphql`
  query ItemTemplateQuery($id: String!) {
    page: sanityItem(id: { eq: $id }) {
      ...Item
    }
    items: allSanityItem(filter: { slug: { current: { ne: null } } }) {
      nodes {
        ...Item
      }
    }
    manufacturers: allSanityManufacturer(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        ...Manufacturer
      }
    }
    itemTypes: allSanityItemType(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        ...ItemType
      }
    }
  }
`;

const ItemTemplate = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    data: { page, items, manufacturers, itemTypes },
    errors,
  } = props;

  const { title, seo, seoImage, hero, smallHero } = page;
  const seoDescription = seo?.meta_description;
  const pageTitle = title || 'Untitled';
  const seoTitle = seo?.seo_title;

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {page && (
        <SEO
          title={pageTitle}
          seoImage={seoImage?.asset?.img?.src}
          seoTitle={seoTitle}
          description={seoDescription}
          keywords={page.seoKeywords}
          path={props.location.pathname}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Navigation />
      <SectionHero smallHero={smallHero} hero={hero} />
      <BuyNowModal modalState={setOpenModal} isOpen={openModal} />
      <SinglePage
        page={page}
        items={items}
        manufacturers={manufacturers}
        itemTypes={itemTypes}
        handleModal={setOpenModal}
      />
    </>
  );
};

export default ItemTemplate;
