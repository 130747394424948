import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import clientConfig from '../../../../../client-config';

import { Container, Row, Col } from '../../../reusecore/Layout';
import { SlickSlider, SliderItem } from '../../../reusecore/SlickSlider';
import Box from '../../../reusecore/Box';
import Heading from '../../../reusecore/Heading';
import Text from '../../../reusecore/Text';
import { List, ListItem } from '../../../reusecore/List';

import ProductPageWrapper from './singlePage.style';
import ContentSections from '../../../pagebuilder/content-sections';
import BlockContent from '../../../block-content';
// import Specifications from '../../cycle/Specifications';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'gatsby';
import SidebarProduct from '../../../layout/sidebarProduct';
import Section from '../../section';
import { grid } from '../../../pagebuilder/section-grid/grid.module.css';
import ItemCell from '../../../itemCell';
import { sortByItemType } from '../../../../utils/tools';

const StyledBuyBtn = styled.div`
  height: 40px;
  width: 140px;
  border-radius: 6px;
  display: block;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background: #416eb6;
  cursor: pointer;
  margin: 1rem auto 0 auto;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const SinglePage = ({ page, itemTypes, manufacturers, items, handleModal }) => {
  const {
    additionalImages = [],
    heroImage,
    itemType,
    manufacturer,
    model,
    price,
    size,
    year,
    tags,
    title,
    _rawBody,
  } = page;
  const allImages = Array.from(additionalImages);

  if (heroImage) {
    allImages.unshift(heroImage);
  }

  const sortedItemTypes = sortByItemType(itemTypes);

  const relatedItems = items?.nodes?.filter((item) => {
    return item.id !== page.id && item.itemType.id === itemType.id && manufacturer?.id === item.manufacturer?.id;
  });

  const settings = {
    // eslint-disable-next-line react/display-name
    customPaging(i) {
      const gatsbyImageData =
        allImages.length > 0 &&
        getGatsbyImageData(allImages[i].image, { layout: 'constrained', maxWidth: 675 }, clientConfig.sanity);
      return (
        allImages[i] && (
          <a>
            <GatsbyImage image={gatsbyImageData} alt={allImages[i]?.alt} />
          </a>
        )
      );
    },
    autoplay: false,
    infinite: true,
    lazyLoad: true,
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots product__thumb',
    speed: 500,
    slidesShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Section>
      <Container>
        <Row reverseMobile>
          <Col xs={12} lg={2}>
            <SidebarProduct itemTypes={sortedItemTypes} manufacturers={manufacturers} items={items} />
          </Col>
          <Col xs={12} lg={10}>
            <Row>
              <Col className="xs-12">
                <ProductPageWrapper>
                  <div style={{ margin: '2rem auto' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link to="/shop">Shop</Link>
                      <Link to={`/${itemType.slug.current}`}>{itemType.title}</Link>
                      <Link to={`/${itemType.slug.current}/${manufacturer.slug.current}`}>{manufacturer.name}</Link>
                      <span>{title}</span>
                    </Breadcrumbs>
                  </div>
                  <Box className="product-details">
                    <Row>
                      <Col md={6}>
                        <Box className="product-image-slider">
                          <SlickSlider {...settings}>
                            {allImages.map((image, index) => {
                              const gatsbyImageData = getGatsbyImageData(
                                image.image,
                                { layout: 'constrained', maxWidth: 675 },
                                clientConfig.sanity
                              );
                              return (
                                <SliderItem key={`product_img_${index}`}>
                                  <GatsbyImage image={gatsbyImageData} alt={image.alt} />
                                </SliderItem>
                              );
                            })}
                          </SlickSlider>
                          <StyledBuyBtn onClick={() => handleModal(true)} isOneImage={allImages?.length === 1}>
                            Buy Now
                          </StyledBuyBtn>
                        </Box>
                      </Col>

                      <Col md={6}>
                        <Box className="specifications">
                          <Heading leftAlign as="h2">
                            Specifications
                          </Heading>
                          <List>
                            <ListItem>
                              <Text as="span"> Model: </Text>
                              <Text as="span"> {model}</Text>
                            </ListItem>
                            <ListItem>
                              <Text as="span"> Year: </Text>
                              <Text as="span"> {year} </Text>
                            </ListItem>
                            {size && (
                              <ListItem>
                                <Text as="span"> Size: </Text>
                                <Text as="span"> {size} </Text>
                              </ListItem>
                            )}
                            <ListItem>
                              <Text as="span"> Price: </Text>
                              <Text as="span"> ${price} </Text>
                            </ListItem>
                            {/*<ListItem>*/}
                            {/*  <Text as="span"> Color: </Text>*/}
                            {/*  <Box className="color-list">*/}
                            {/*    <Text as="span" className="color Black">*/}
                            {/*      {' '}*/}
                            {/*    </Text>*/}
                            {/*    <Text as="span" className="color Blue">*/}
                            {/*      {' '}*/}
                            {/*    </Text>*/}
                            {/*    <Text as="span" className="color red">*/}
                            {/*      {' '}*/}
                            {/*    </Text>*/}
                            {/*  </Box>*/}
                            {/*</ListItem>*/}
                          </List>

                          <div className="about-pera">
                            {_rawBody && <BlockContent blocks={_rawBody} />}
                            {/* temporarily disable tags */}
                            {/*tags.length > 0 && (
                              <ul style={{ marginTop: '1em' }}>
                                {tags?.map((tag, index) => {
                                  // hack to remove sanity tag Create being saved in label
                                  // https://github.com/rosnovsky/sanity-plugin-autocomplete-tags/issues/14
                                  let displayTag = tag.label;
                                  const createIndex = displayTag.indexOf('Create "');
                                  if (createIndex !== -1) {
                                    displayTag = displayTag.substring(8, displayTag.length - 1);
                                  }
                                  return (
                                    <li style={{ display: 'inline-block', marginRight: '5px' }} key={`tag-${index}`}>
                                      <Link to={`/shop?tag=${tag.value.toLowerCase()}`}>
                                        <Text as="span">{displayTag.toLowerCase()}</Text>{' '}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            )*/}
                          </div>
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                  {/*<Box className="product-rating-wrap">*/}
                  {/*  <Row>*/}
                  {/*    <Col md={4} sm={6}>*/}
                  {/*      <Box className="product-rating-block">*/}
                  {/*        <Box className="rating-icon">*/}
                  {/*          <FaStar />*/}
                  {/*        </Box>*/}
                  {/*        <Box className="rating-desc">*/}
                  {/*          <Heading as="h3">Highly rated reviews</Heading>*/}
                  {/*          <Text>Felis facilisis Ut vel hinc solet tincidunt Aliquam porttitor mauris sit.</Text>*/}
                  {/*        </Box>*/}
                  {/*      </Box>*/}
                  {/*    </Col>*/}
                  {/*    <Col md={4} sm={6}>*/}
                  {/*      <Box className="product-rating-block">*/}
                  {/*        <Box className="rating-icon">*/}
                  {/*          <FaStar />*/}
                  {/*        </Box>*/}
                  {/*        <Box className="rating-desc">*/}
                  {/*          <Heading as="h3">Highly rated reviews</Heading>*/}
                  {/*          <Text>Felis facilisis Ut vel hinc solet tincidunt Aliquam porttitor mauris sit.</Text>*/}
                  {/*        </Box>*/}
                  {/*      </Box>*/}
                  {/*    </Col>*/}
                  {/*    <Col md={4} sm={6}>*/}
                  {/*      <Box className="product-rating-block">*/}
                  {/*        <Box className="rating-icon">*/}
                  {/*          <FaStar />*/}
                  {/*        </Box>*/}
                  {/*        <Box className="rating-desc">*/}
                  {/*          <Heading as="h3">Highly rated reviews</Heading>*/}
                  {/*          <Text>Felis facilisis Ut vel hinc solet tincidunt Aliquam porttitor mauris sit.</Text>*/}
                  {/*        </Box>*/}
                  {/*      </Box>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</Box>*/}
                  {relatedItems.length > 0 && (
                    <div style={{ margin: '20px 0' }}>
                      <Heading leftAlign as="h2">
                        More by {manufacturer.name}
                      </Heading>
                      <Box style={{ marginTop: '20px' }}>
                        <ol className={grid}>
                          {relatedItems?.map((item) => {
                            return <ItemCell key={item.id} item={item} />;
                          })}
                        </ol>
                      </Box>
                    </div>
                  )}
                </ProductPageWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
        {page && page.content && <ContentSections content={page.content} />}
      </Container>
    </Section>
  );
};

export default SinglePage;
